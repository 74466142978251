* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  background:linear-gradient(to bottom right,#222,#333) !important;
  color:#fff !important;
}
html,body,#root{
  height:100%;
}
a{
  color:#17a2b8;
  text-decoration: none; 
}
header{
  background:linear-gradient(to bottom right, #333, #313131, #494949 80%);

  color:#fff;
  padding:5px;
  padding-bottom:1px;
}
hr{
  border-color:#fff !important;
}
.forecast{
  border-radius:5px;
  background:rgba(255, 255, 255, 0.055);
  
}
.modal.dark .modal-content{
  background:#333;
}
.modal-header .close{
  color:#fff;
}